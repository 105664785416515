body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  padding-right: 10px;
}

.info {
  padding-top: 15vh;
  padding-bottom: 2vh;
}

.ig-logo {
  height: 10vmin;
  padding-bottom: 3vh;
}

.gh-logo {
  padding-left: 8px;
}

.InfoRow {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.CardImg {
  height: auto;
  width: auto;
  max-width: 15vw;
  max-height: 13vh;
  object-fit: cover;
  min-width: 200px;
}

.Card {
  padding: 5px;
  min-width: 212px;
  max-width: 15vw;
}

.CardBody {
  background-color: #e9e9e9 ;
}

#EventsBG {
  background-color: #3c5b9e;
}

.App-link {
  color: #aee4e8;
}
.App-link:visited {
  color: #aee4e8;
}
.App-link:hover {
  color: #aee4e8;
}
.App-link:active {
  color: #aee4e8;
}

::-webkit-scrollbar {
  display: none;
}


.clock {
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: center;
            justify-content: center;
    margin-bottom: 2px;
    margin-top: 10px;
}

.clock-display {
    position: relative;
}

.clock-text {
    font-size: 2rem;
    font-weight: 500;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 100%;
    -webkit-justify-content: center;
            justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.clock-unit {
    font-size: 0.75rem;
}

.circle {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    transition: stroke-dashoffset .5s cubic-bezier(.4, 0, 0, 1.7);
}

.row .list-group-item {
    margin-top:10px;
    height:155px;
}
#flyoutMenu {
    width: 100vw;
    height: 100vh;
    background-color: #FFE600;
    position: fixed;
    top: 0;
    left: 0;
    transition: -webkit-transform .3s
    cubic-bezier(0, .52, 0, 1);
    transition: transform .3s
    cubic-bezier(0, .52, 0, 1);
    transition: transform .3s
    cubic-bezier(0, .52, 0, 1), -webkit-transform .3s
    cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 100;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 5vh;
    text-align: center;
}

#flyoutMenu.hide {
    -webkit-transform: translate3d(-100vw, 0, 0);
            transform: translate3d(-100vw, 0, 0);
}

#flyoutMenu.show {
    -webkit-transform: translate3d(0vw, 0, 0);
            transform: translate3d(0vw, 0, 0);
    overflow: hidden;
}

#flyoutMenu h2 a {
    color: #333;
    margin-left: 15px;
    margin-top: 15px;
}

#flyoutMenu h2 a:hover {
    text-decoration: underline;
}
.button {
    position: fixed;
    right: 20px;
    top: 20px;
    cursor: pointer;
    display: inline-block;
    z-index: 200;
}

.button:hover {
    cursor: pointer;
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.menu .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.menu .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.menu .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
}
