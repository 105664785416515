#flyoutMenu {
    width: 100vw;
    height: 100vh;
    background-color: #FFE600;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform .3s
    cubic-bezier(0, .52, 0, 1);
    overflow: scroll;
    z-index: 100;
    justify-content: center;
    padding-top: 5vh;
    text-align: center;
}

#flyoutMenu.hide {
    transform: translate3d(-100vw, 0, 0);
}

#flyoutMenu.show {
    transform: translate3d(0vw, 0, 0);
    overflow: hidden;
}

#flyoutMenu h2 a {
    color: #333;
    margin-left: 15px;
    margin-top: 15px;
}

#flyoutMenu h2 a:hover {
    text-decoration: underline;
}