.button {
    position: fixed;
    right: 20px;
    top: 20px;
    cursor: pointer;
    display: inline-block;
    z-index: 200;
}

.button:hover {
    cursor: pointer;
    transform: scale(1.1, 1.1);
}

.bar1, .bar2, .bar3 {
    width: 30px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.menu .bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.menu .bar2 {
    opacity: 0;
}

/* Rotate last bar */
.menu .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
}