.App {
  text-align: center;
}

.App-logo {
  height: 8vmin;
  padding-right: 10px;
}

.info {
  padding-top: 15vh;
  padding-bottom: 2vh;
}

.ig-logo {
  height: 10vmin;
  padding-bottom: 3vh;
}

.gh-logo {
  padding-left: 8px;
}

.InfoRow {
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.CardImg {
  height: auto;
  width: auto;
  max-width: 15vw;
  max-height: 13vh;
  object-fit: cover;
  min-width: 200px;
}

.Card {
  padding: 5px;
  min-width: 212px;
  max-width: 15vw;
}

.CardBody {
  background-color: #e9e9e9 ;
}

#EventsBG {
  background-color: #3c5b9e;
}

.App-link {
  color: #aee4e8;
}
.App-link:visited {
  color: #aee4e8;
}
.App-link:hover {
  color: #aee4e8;
}
.App-link:active {
  color: #aee4e8;
}

::-webkit-scrollbar {
  display: none;
}


.clock {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2px;
    margin-top: 10px;
}

.clock-display {
    position: relative;
}

.clock-text {
    font-size: 2rem;
    font-weight: 500;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    width: 100%;
}

.clock-unit {
    font-size: 0.75rem;
}

.circle {
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
    transition: stroke-dashoffset .5s cubic-bezier(.4, 0, 0, 1.7);
}

.row .list-group-item {
    margin-top:10px;
    height:155px;
}